<template>
  <div class="min-h-screen relative">
    <LayoutTopBar @toggle-sidebar="toggleSidebar" />

    <LayoutSideBar @hide-sidebarr="hideSidebar" class="hidden md-big:block" />
    <Sidebar
      class="md-big:hidden"
      v-model:visible="showSidebar"
      :autoZIndex="false"
      ><LayoutSideBar @hide-sidebar="hideSidebar"
    /></Sidebar>
    <!-- Page Content -->
    <main
      class="w-full pt-24 md-big:pt-32 ms-0 md-big:ms-72 md-big:w-[calc(100%-18rem)]"
      :class="{
        'pt-44 md-big:pt-44': user && !user.confirmed,
      }"
    >
      <div class="container">
        <slot />
      </div>
    </main>
    <Toast position="bottom-right" />
    <Toast position="top-right" group="bc">
      <template #message="slotProps">
        <div class="flex flex-col w-full">
          <div class="font-medium text-lg my-3 text-900">
            {{ slotProps.message.summary }}
          </div>
          <div class="mb-4">{{ slotProps.message.detail }}</div>
          <NuxtLink class="mb-3 bg-slate-800 p-2 px-5 rounded-lg" to="/messages"
            >View Message</NuxtLink
          >
        </div>
      </template>
    </Toast>
    <LayoutMainFooter />
    <LayoutWalletModal />
    <HomeLevelUpSplash />
  </div>
</template>

<script setup>
import { useWebsiteInfoStore } from '~/stores/website';
import { useUserStore } from '@/stores/user';
import { useToast } from 'primevue/usetoast';

import { ref } from 'vue';

const userStore = useUserStore();
const websiteInfoStore = useWebsiteInfoStore();
const levelStore = useLevelStore();

const showSidebar = ref(false);

const levels = computed(() => levelStore.levels);
const user = computed(() => userStore.user);
const loggedIn = computed(() => userStore.loggedIn);
const website = computed(() => websiteInfoStore.website);
const toast = useToast();

const userChannel = ref(null);
const countryChannel = ref(null);
const globalAuthChannel = ref(null);

watch(user, (newValue) => {
  if (newValue && newValue.id) {
    listenToUser();
  } else {
    stopListeningToUser();
  }
});

onMounted(async () => {
  await websiteInfoStore.fetchWebsiteInfo();
  await userStore.fetchUser();
  installAnalytics();

  window.Echo.channel('global').listen(
    '.App\\Events\\UpdateCoinmaster',
    (e) => {
      websiteInfoStore.updateWebsiteInfoFromBroadcast(e.websites);
    }
  );

  if (loggedIn.value) {
    listenToUser();
  }
});

const listenToUser = () => {
  // Assign the channel subscription
  userChannel.value = `App.User.${website.value.id}.${user.value.id}`;
  window.Echo.private(userChannel.value)
    .listen('.App\\Events\\UpdateTokens', (e) => {
      userStore.updateUser({ total_tokens: e.total_tokens });
    })
    .listen('.App\\Events\\NewDeposit', (e) => {
      toast.add({
        severity: 'success',
        summary: 'Deposit Received',
        detail: `A new deposit of ${e.amount} ${e.currency} was received and is awaiting confirmation`,
        life: 15000,
      });
    })
    .listen('.App\\Events\\ConfirmedDeposit', (e) => {
      toast.add({
        severity: 'success',
        summary: 'Deposit Confirmed',
        detail: `The deposit of ${e.amount} ${e.currency} is now confirmed`,
        life: 15000,
      });
      userStore.toggleWalletTab(false);
    })
    .listen('.App\\Events\\NewUserBan', async () => {
      await userStore.logout();
      toast.add({
        severity: 'error',
        summary: 'Due to abuse you cannot use our faucet anymore.',
        detail: "Do not contact us as this decision won't change.",
        life: 15000,
      });
    })
    .listen('.App\\Events\\UpdateUserLevel', async (e) => {
      if (e.is_new_level) {
        await levelStore.fetchLevels(true); // Fetch level again since user now can see the bonus unlocked

        setTimeout(() => {
          userStore.levelUp(
            levels.value.find((l) => l.id === e.user_level.level_id)
          );
        }, 1000);
      }
      userStore.updateUser({ user_level: e.user_level });
    })
    .listen('.received-roll', async (e) => {
      toast.add({
        severity: 'info',
        summary: 'Free Roll received',
        detail: `You have received ${e.amount} roll as a reward from ${e.source}`,
        life: 10000,
      });
      userStore.toggleReceivedRolls(true);
    })
    .listen('.received-reward', async (e) => {
      userStore.updateUser({ rewards: e.rewards });
      await userStore.fetchUser(true);
      await levelStore.fetchLevels(true);
      userStore.toggleReceivedReward(true);
    });

  // Assign the Country channel subscription
  countryChannel.value = `${website.value.id}-country-${user.value.country_id}`;
  window.Echo.private(countryChannel.value).listen('.new-mass-message', (e) =>
    handleMassMessageReceived(e)
  );

  globalAuthChannel.value = `${website.value.id}-global-authenticated`;
  window.Echo.private(globalAuthChannel.value).listen(
    '.new-mass-message',
    (e) => handleMassMessageReceived(e)
  );
};

const handleMassMessageReceived = (e) => {
  userStore.updateUser({
    unread_message_count: user.value.unread_message_count + 1,
  });

  const truncatedTitle =
    e.message.length > 40 ? e.message.substring(0, 40) + '...' : e.message;

  toast.add({
    severity: 'info',
    summary: e.title,
    detail: truncatedTitle,
    group: 'bc',
    life: 14000,
  });
};

const stopListeningToUser = () => {
  if (userChannel.value) {
    window.Echo.leave(userChannel.value);
    window.Echo.leave(countryChannel.value);
    window.Echo.leave(globalAuthChannel.value);
    userChannel.value = null; // Reset the channel variable
    countryChannel.value = null; // Reset the channel variable
  }
};

const toggleSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const hideSidebar = () => {
  showSidebar.value = false;
};

const installAnalytics = () => {
  const scriptContainer = document.createElement('div');
  scriptContainer.innerHTML = website.value.google_analytics;

  const scriptElements = scriptContainer.querySelectorAll('script');

  scriptElements.forEach((scriptElement) => {
    const newScriptTag = document.createElement('script');

    const srcAttribute = scriptElement.getAttribute('src');

    if (srcAttribute) {
      newScriptTag.src = srcAttribute;
    } else {
      newScriptTag.textContent = scriptElement.textContent;
    }
    document.body.appendChild(newScriptTag);
  });
};
</script>
