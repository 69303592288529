<template>
  <svgo-coinfaucet v-if="props.website === 'coinfaucet'" filled />
  <svgo-freebfg v-else-if="props.website === 'freebfg'" filled />
  <svgo-freebinancecoin
    v-else-if="props.website === 'freebinancecoin'"
    filled
  />
  <svgo-freebitcoin v-else-if="props.website === 'freebitcoin'" filled />
  <svgo-freebittorrent v-else-if="props.website === 'freebittorrent'" filled />
  <svgo-freecardano v-else-if="props.website === 'freecardano'" filled />
  <svgo-freechain v-else-if="props.website === 'freechain'" filled />
  <svgo-freedash v-else-if="props.website === 'freedash'" filled />
  <svgo-freedoge v-else-if="props.website === 'freedoge'" filled />
  <svgo-freeethereum v-else-if="props.website === 'freeethereum'" filled />
  <svgo-freelitecoin v-else-if="props.website === 'freelitecoin'" filled />
  <svgo-freematic v-else-if="props.website === 'freematic'" filled />
  <svgo-freenem v-else-if="props.website === 'freenem'" filled />
  <svgo-freeneo v-else-if="props.website === 'freeneo'" filled />
  <svgo-freepancake v-else-if="props.website === 'freepancake'" filled />
  <svgo-freeshibainu v-else-if="props.website === 'freeshibainu'" filled />
  <svgo-freesteam v-else-if="props.website === 'freesteam'" filled />
  <svgo-freetether v-else-if="props.website === 'freetether'" filled />
  <svgo-freetron v-else-if="props.website === 'freetron'" filled />
  <svgo-freeusdcoin v-else-if="props.website === 'freeusdcoin'" filled />
</template>

<script setup>
const props = defineProps({
  website: String,
});
</script>
