<template>
  <div>
    <h1 class="text-center mb-4">
      {{ $t('app.withdraw_coin', { coin: website.coin_name }) }}
    </h1>

    <div class="mb-4">
      <h3 class="text-lg font-semibold">
        {{
          $t('app.min_withdraw', {
            min_withdrawl: website.min_withdrawl,
            currency: website.currency,
          })
        }}
      </h3>
    </div>

    <div class="mt-10">
      <label class="block mb-2">
        {{ $t('app.amount') }}
        <a href="#" @click="setMaxAmount" class="text-blue-500 text-xs ml-2">
          {{ $t('app.withdraw_all') }}
        </a>
      </label>
      <InputNumber
        v-model="amount"
        class="w-60 md:w-80"
        :maxFractionDigits="10"
        :placeholder="$t('app.enter_amount')"
      />
    </div>

    <div class="mt-10" v-if="website.withdrawl_var_1">
      <label class="block mb-2">{{ website.withdrawl_var_1 }} </label>
      <p class="text-gray-400">
        {{ withdrawlVar1 }}
      </p>
    </div>

    <div class="mt-10">
      <label class="block mb-2">{{ $t('app.transaction_fees') }}</label>
      <p class="text-gray-400">
        {{ website.withdrawl_fee }} {{ website.currency }}
      </p>
    </div>

    <div class="mt-10">
      <label class="block mb-2">{{ $t('app.amount_to_be_sent') }}</label>
      <p class="text-gray-400">
        {{ computedAmountToSend }} {{ website.currency }}
      </p>
    </div>

    <div class="mt-10">
      <label class="block mb-2"
        >{{ website.coin_name }} {{ $t('app.address') }}</label
      >
      <p class="text-gray-400" v-if="user.wallet_address">
        {{ user.wallet_address }}
      </p>
      <NuxtLink v-else to="/settings">{{ $t('app.settings') }}</NuxtLink>
    </div>

    <div
      v-if="website.withdrawal_note"
      class="mt-10 text-gray-500"
      v-html="website.withdrawal_note"
    ></div>

    <Button @click="withdrawNow" class="mt-8" :disabled="loading">
      {{ $t('app.withdraw') }}
    </Button>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useWebsiteInfoStore } from '@/stores/website';
import { useUserStore } from '@/stores/user';
import { useToast } from 'primevue/usetoast';

const api = useApi();
const websiteInfoStore = useWebsiteInfoStore();
const userStore = useUserStore();
const { t } = useI18n();
const toast = useToast();

const website = computed(() => websiteInfoStore.website);
const user = computed(() => userStore.user);
const computedAmountToSend = computed(() => {
  // Calculate the amount to be sent after fees
  if (!amount.value) {
    return 0;
  }

  return parseFloat(amount.value) - parseFloat(website.value.withdrawl_fee);
});

const amount = ref(0);
const walletAddress = ref('');
const withdrawlVar1 = ref('');
const withdrawResult = ref('');
const loading = ref(false);

onMounted(() => {
  walletAddress.value = user.value.wallet_address;
  withdrawlVar1.value = user.value.withdrawl_var_1;
});

// Method to handle withdrawal
const withdrawNow = async () => {
  loading.value = true;
  const data = await api
    .execute('/api/ajax-withdraw', 'post', {
      amount: amount.value,
      wallet_address: walletAddress.value,
      withdrawl_var_1: withdrawlVar1.value,
    })
    .catch(() => (loading.value = false));

  if (!data.status) return;
  loading.value = false;
  withdrawResult.value = data.email
    ? t('app.email_sent')
    : t('app.withdrawal_success');

  userStore.updateUser({
    total_coins: data.balance,
  });

  toast.add({
    severity: 'success',
    summary: 'Success',
    detail: withdrawResult.value,
    life: 3000,
  });
};

// Method to set maximum withdrawal amount
const setMaxAmount = (e) => {
  e.preventDefault();
  amount.value = parseFloat(user.value.total_coins);
};
</script>
