<template>
  <footer>
    <div v-if="website" class="ms-2 md-big:ms-72 md-big:w-[calc(100%-18rem)]">
      <section class="w-full bg-opacity-10 text-center pt-10">
        <a
          v-for="partner in partners"
          :key="partner.domain"
          :href="`https://${partner.domain}`"
          target="_blank"
          class="text-gray-600 bg-[--surface-card] h-auto inline-block my-1 mx-1 xl:my-[10px] xl:mx-[10px] p-4 rounded-xl hover:scale-[1.05] transition ease-in-out"
          ><CommonLogo
            :website="partner.img_folder"
            class="opacity-100 partners-img"
        /></a>
      </section>
      <div class="p-8 text-center">
        <p>
          {{ moment().year() }}, {{ website.fancy_domain }} -
          {{ $t('app.copyright') }}
        </p>
        <p class="mt-2">
          <NuxtLink class="cursor-pointer underline" to="gdpr"
            >{{ $t('app.gdpr') }}
          </NuxtLink>
          |
          <a class="underline" target="_blank" :href="`${$path}/blog`">{{
            $t('app.blog')
          }}</a>
          |
          <a
            class="underline"
            :href="`${$path}/privacy-policy`"
            target="_blank"
            >{{ $t('app.privacy_policy') }}</a
          >
          |
          <a
            class="underline"
            :href="`${$path}/terms-and-conditions`"
            target="_blank"
            >{{ $t('app.terms_conditions') }}</a
          >
          <span v-for="article in articles" :key="article.slug">
            |
            <a
              class="underline"
              target="_blank"
              :href="`${$path}/articles/${article.slug}`"
              >{{ article.footer_title }}</a
            >
          </span>
        </p>
      </div>
    </div>
  </footer>
  <div
    v-if="shouldShowAd(website.ad_3)"
    class="fixed bottom-0 w-full z-50 block sm:hidden"
  >
    <Icon
      @click="closeAd"
      icon="fa-regular fa-circle-xmark"
      class="text-4xl absolute left-2.5 top-0 rounded-full bg-black bg-opacity-70 text-white leading-4 transform -translate-y-1/2"
    ></Icon>
    <div v-html="website.ad_3"></div>
  </div>

  <div
    v-if="shouldShowAd(website.ad_4)"
    class="fixed bottom-0 left-0 right-0 flex justify-center bg-black bg-opacity-80 z-50 h-22 cursor-pointer font-sans overflow-visible hidden sm:block"
  >
    <div class="flex justify-center" v-html="website.ad_4"></div>

    <Icon
      @click="closeAd"
      icon="fa-regular fa-circle-xmark"
      class="text-4xl absolute left-1.5 top-0 rounded-full bg-black bg-opacity-70 text-white leading-4 transform -translate-y-1/2"
    ></Icon>
  </div>
</template>
<script setup>
import { useWebsiteInfoStore } from '@/stores/website';
import moment from 'moment';

const websiteInfoStore = useWebsiteInfoStore();
const route = useRoute();

const website = computed(() => websiteInfoStore.website);
const articles = computed(() => websiteInfoStore.footerArticles);
const partners = computed(() => websiteInfoStore.partners);

const showAd = ref(true);

const isTestDomain = computed(() => {
  return window.location.href.includes('.test');
});

const isExcludedRoute = computed(() => {
  const excludedRoutes = ['/games', '/contests/weekly', '/contests/monthly'];
  return excludedRoutes.includes(route.path);
});

const shouldShowAd = (adContent) => {
  return (
    adContent && showAd.value && !isExcludedRoute.value && !isTestDomain.value
  );
};

const closeAd = () => {
  showAd.value = false;
};
</script>
