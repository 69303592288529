<template>
  <div class="min-h-[300px]">
    <div class="mt-2 md:mt-0 md:flex items-center">
      <label class="text-base">{{ $t('app.deposit_in') }}</label>
      <Dropdown
        @change="onCurrencySelected"
        v-model="selectedCurrency"
        :options="currencies"
        placeholder="Select currency"
        class="mb-2 md:mb-0 md:ml-3 w-full md:w-80 md:mr-2"
        optionValue="code"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex align-center">
            <span>
              {{ getCurrencyName(slotProps.value) }} ({{
                currentCurrency.network
              }})
            </span>
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex align-center">
            <span>
              {{ slotProps.option.name }} ({{ slotProps.option.network }})
            </span>
          </div>
        </template>
      </Dropdown>
      <Button
        v-if="!currentUserDepositDetails"
        @click="getPayment"
        class=""
        :disabled="loading || getPaymentButton === false"
      >
        {{ $t('app.generate_address') }}
      </Button>
    </div>
    <div v-if="loading" class="md:grid grid-cols-3 md:space-x-10 mt-10">
      <div class="flex justify-center">
        <Skeleton size="200px" />
      </div>
      <div class="md:col-span-2 mt-4 md:mt-0">
        <CommonSkeleton :length="5" item-height="1.5rem" />
      </div>
    </div>

    <div
      class="md:flex md:space-x-10 mt-10"
      v-if="!loading && currentUserDepositDetails"
    >
      <div class="flex justify-center">
        <QRCodeVue3
          :width="250"
          :height="250"
          :key="currentUserDepositDetails.wallet_address"
          :value="currentUserDepositDetails.wallet_address"
          :qr-options="{
            errorCorrectionLevel: 'H',
          }"
          imgclass="rounded-lg"
          :image-options="{
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 10,
          }"
          :corners-square-options="{
            type: 'extra-rounded',
            color: '#424b57',
          }"
          :corners-dot-options="{
            type: 'extra-rounded',
            color: '#41B883',
          }"
          :dots-options="{
            type: 'extra-rounded',
            color: '#41B883',
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                { offset: 0, color: '#2dd4bf' },
                { offset: 1, color: '#424b57' },
              ],
            },
          }"
          :download="false"
        />
      </div>
      <div v-if="currentCurrency" class="mt-4 md:mt-0">
        <h3 class="mb-1">
          {{
            $t('app.deposit_address_title', {
              currency: currentCurrency.name,
            })
          }}
        </h3>

        <p class="text-gray-400 underline">
          {{
            $t('app.deposit_address_description', {
              network: currentCurrency.network,
            })
          }}
        </p>

        <div class="mt-6">
          <p class="text-sm">{{ $t('app.copy_address') }}</p>
          <div class="flex items-center mt-2">
            <Icon
              @click="
                () =>
                  copyTextToClipboard(currentUserDepositDetails.wallet_address)
              "
              icon="fa-regular fa-copy"
              class="cursor-pointer p-mr-2 text-primary"
            />
            <InputText
              :value="currentUserDepositDetails.wallet_address"
              class="ml-2 pointer-events-none w-full"
            />
          </div>
        </div>

        <div class="mt-2 mb-3" v-if="currentCurrency.extra_id">
          <p class="text-sm">
            {{ `${$t('app.copy')} ${currentCurrency.extra_id}` }}
          </p>
          <div class="flex items-center mt-2">
            <Icon
              @click="
                () => copyTextToClipboard(currentUserDepositDetails.extra_id)
              "
              icon="fa-regular fa-copy"
              class="cursor-pointer p-mr-2 text-primary"
            />
            <InputText
              :value="currentUserDepositDetails.extra_id"
              class="ml-2 pointer-events-none w-full"
            />
          </div>
        </div>
        <p class="mt-4 font-semibold">
          {{ $t('app.minimum_deposit') }}
          <span class="text-gray-400">{{ currentCurrency.minimum }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import QRCodeVue3 from 'qrcode-vue3';

const api = useApi();
const toast = useToast();

const loading = ref(false);

const currencies = ref([]);

const selectedCurrency = ref(null);
const userDepositDetails = ref(null);
const getPaymentButton = ref(false);

onMounted(async () => {
  init();
});

const init = async () => {
  loading.value = true;
  const response = await api.execute('/api/deposits/currencies');
  loading.value = false;

  currencies.value = response.currencies;
  userDepositDetails.value = response.user_deposit_details;
  selectedCurrency.value = currencies.value[0].code;
  onCurrencySelected();
};

const currentCurrency = computed(() => {
  return currencies.value.find((c) => c.code === selectedCurrency.value);
});

const currentUserDepositDetails = computed(() => {
  if (!userDepositDetails.value) return;
  return userDepositDetails.value.find(
    (c) => c.currency_id === currentCurrency.value?.id
  );
});

const onCurrencySelected = () => {
  getPaymentButton.value = true;
};

const getPayment = async () => {
  if (
    userDepositDetails.value.find(
      (c) => c.currency_id === currentCurrency.value.id
    )
  ) {
    return;
  }
  loading.value = true;
  const response = await api.execute(
    `/api/deposits/${currentCurrency.value.code}/get-payment`
  );
  loading.value = false;

  userDepositDetails.value = response;
};

const getCurrencyName = (value) => {
  const currency = currencies.value.find((c) => c.code === value);
  return currency ? currency.name : '';
};

const copyTextToClipboard = async (value) => {
  if (window.isSecureContext) {
    await navigator.clipboard.writeText(value);
    toast.add({
      severity: 'info',
      summary: 'Value copied to clipboard',
      detail: '',
      life: 1500,
    });
  }
};
</script>
